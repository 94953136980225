import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Snackbar, useMediaQuery, useTheme } from "@mui/material";

import {
  EnvironmentType,
  MixpanelEvents,
  snackbarDesktop,
  snackbarMobile,
  STEPS,
  StorageKeys,
} from "./constants";
import { OfferInfo } from "./types";
import "./App.css";
import { getOfferInfo } from "./services/requests";
import mixpanel from "mixpanel-browser";
import {
  getFromSessionStorage,
  getWithExpiry,
  removeStorageItem,
  setWithExpiry,
} from "./services/storage";

export interface IAppContext {
  setError: (error: string) => void;
  isDesktop: boolean;
  offerInfo: OfferInfo;
  offerLoading: boolean;
  callEvent: (event: MixpanelEvents, data?: any) => void;
  identifyUser: (email: string) => void;
  updateProfile: () => void;
}
type AppContextType = IAppContext | {};

const AppContext = createContext<AppContextType>({});

export const useAppContext = (): AppContextType => useContext(AppContext);

function Context({ children }: { children: ReactNode }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const [offerInfo, setOfferInfo] = useState<OfferInfo | null>(null);
  const [offerLoading, setOfferLoading] = useState<boolean>(false);
  const [mixpanelInitialized, setMixpanelInitialized] = useState(false);

  useEffect(() => {
    if (
      !location.pathname.includes("step2") &&
      !location.pathname.includes("step3") &&
      !location.pathname.includes("step4") &&
      !location.pathname.includes("step5")
    ) {
      const search = new URLSearchParams(location.search);
      const previousSlug = getWithExpiry(StorageKeys.SLUG_KEY);
      let { slug } = params;
      if (!slug || slug === "step1" || slug === "getonboard") {
        slug = previousSlug || "default";
      }
      if (search.has(StorageKeys.OFFER_KEY)) {
        slug = search.get(StorageKeys.OFFER_KEY) || slug;
      }
      setWithExpiry(StorageKeys.SLUG_KEY, slug);
      if (previousSlug !== slug && slug !== "default") {
        removeStorageItem(StorageKeys.CURRENT_STEP);
        removeStorageItem(StorageKeys.USER_DATA_KEY);
        removeStorageItem(StorageKeys.TOKEN_KEY);
      }
    }
  }, [params, location]);

  useEffect(() => {
    const currentStep = getWithExpiry(StorageKeys.CURRENT_STEP);
    const pathname = location.pathname.replace("/", "");
    if (currentStep && currentStep !== pathname) {
      const search = location.search;
      navigate(`/${currentStep}${search}`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pathname = location.pathname.replace("/", "");
    if (STEPS[pathname as STEPS]) {
      setWithExpiry(StorageKeys.CURRENT_STEP, pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
      debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
    });
    setMixpanelInitialized(true);
  }, []);

  const getCurrentOffer = useCallback(async () => {
    try {
      setOfferLoading(true);
      const search = new URLSearchParams(location.search);
      let slug = params.slug;
      if (search.has(StorageKeys.OFFER_KEY)) {
        slug = search.get(StorageKeys.OFFER_KEY) || "";
      }
      if (!slug) {
        slug = getWithExpiry(StorageKeys.SLUG_KEY) || "default";
      }
      callEvent(MixpanelEvents.OFFER_USED, { offer: slug || "default" });
      const resp = await getOfferInfo(slug);
      setOfferInfo(resp);
    } catch (e: any) {
      setError(
        e?.data?.message ||
          e?.data?.error ||
          "Unexpected issue. Cannot show current offer."
      );
    } finally {
      setOfferLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const updateProfile = useCallback(() => {
    const parseUser = getWithExpiry(StorageKeys.USER_DATA_KEY) || {};
    if (parseUser && Object.keys(parseUser).length) {
      if (parseUser?.password) {
        delete parseUser?.password;
      }
      mixpanel.people.set({ ...parseUser, offer: offerInfo?.slug });
    }
  }, [offerInfo]);

  const callEvent = useCallback(
    (event: MixpanelEvents, data: any) => {
      if (mixpanelInitialized) {
        const generatedEvent = `fe-${
          EnvironmentType[process.env.NODE_ENV]
        }-${event}`;

        mixpanel.track(generatedEvent, data);
      }
    },
    [mixpanelInitialized]
  );

  const identifyUser = useCallback((email: string) => {
    const prevEmail = getFromSessionStorage(StorageKeys.USER_EMAIL_IDENTIFIER);
    if (prevEmail) {
      if (prevEmail !== email) {
        mixpanel.alias(email, prevEmail);
      }
    } else {
      mixpanel.identify(email);
    }
  }, []);

  useEffect(() => {
    getCurrentOffer();
  }, [getCurrentOffer]);

  return (
    <AppContext.Provider
      value={{
        setError,
        isDesktop,
        offerInfo,
        offerLoading,
        callEvent,
        identifyUser,
        updateProfile,
      }}
    >
      {children}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}
        anchorOrigin={isDesktop ? snackbarDesktop : snackbarMobile}
      >
        <Alert
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </AppContext.Provider>
  );
}

export default Context;
