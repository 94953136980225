import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    error: {
      main: red.A400,
    },
    info: {
      main: "#484848",
    },
  },
  additionalColors: {
    grey: "#343434",
    lightGreyBackground: "#FAFAFA",
    appBarGreyBackground: "#F8F8F8",
    footerSubMenu: "#919191",
    greyScale4: "#E8E8E8",
  },
  typography: {
    fontFamily: "Suisse Intl, serif",
    h1: {
      fontSize: 48,
      fontWeight: 500,
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
      textTransform: "uppercase",
      "@media (max-width:899px)": {
        fontSize: 36,
      },
    },
    h3: {
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: 24,
    },
    h6: {
      fontSize: 20,
    },
    body1: {
      fontFamily: "Suisse Intl, serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.95,
      color: "rgb(69 69 69)",
      "@media (max-width:899px)": {
        fontSize: 15,
      },
    },
    body2: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.8,
    },
    subtitle1: {
      fontFamily: "Suisse Intl, serif",
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.95,
      "@media (max-width:899px)": {
        lineHeight: 1.65,
        fontSize: 15,
      },
    },
    subtitle2: {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight: 300,
      lineHeight: 1.66,
      margin: "10px 0",
      "@media (max-width:899px)": {
        fontSize: 12,
      },
    },
    caption: {
      fontSize: 14,
      color: "#767676",
      fontWeight: 400,
      lineHeight: 1.42,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 0,
          paddingX: "16px",
          paddingTop: 12,
          paddingBottom: 12,
          fontSize: 18,
          lineHeight: "22px",
          fontWeight: 400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 20,
          "@media (max-width:600px)": {
            fontSize: 20,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)",
            "-webkit-text-fill-color": "rgba(0, 0, 0, 0.6)",
          },
          "@media (max-width:600px)": {
            fontSize: 18,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
          "& .MuiCheckbox-root": {
            paddingTop: 0,
            paddingRight: "5px",
            paddingBottom: 0,
          },
        },
        label: {
          fontSize: 14,
          lineHeight: "20px",
          paddingRight: "5px",
          height: "100%",
          color: "rgb(60 60 60)",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            background: "none",
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
