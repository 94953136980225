import App from "../App";
import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Route } from "react-router-dom";
import React, { lazy } from "react";

import Steps from "../Steps";

const Step1Component = lazy(() => import("../forms/step1"));
const Step2Component = lazy(() => import("../forms/step2"));
const Step3Component = lazy(() => import("../forms/step3"));
const Step4Component = lazy(() => import("../forms/step4"));
const Step5Component = lazy(() => import("../forms/step5"));
const ReferralLanding = lazy(() => import("../components/referral"));

export const appRoutes = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route path="/referral" element={<ReferralLanding />} />
        <Route path={"/*"} element={<Steps />}>
          <Route path=":slug" element={<Step1Component />} />
          <Route path="step1" element={<Step1Component />} />
          <Route path="step2" element={<Step2Component />} />
          <Route path="step3" element={<Step3Component />} />
          <Route path="step4" element={<Step4Component />} />
          <Route path="step5" element={<Step5Component />} />
          <Route index element={<Step1Component />} />
        </Route>
      </Route>
    )
  );
};
