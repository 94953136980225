import React, { Suspense } from "react";
import Context from "./Context";
import { Outlet } from "react-router-dom";
import Loader from "./components/shared/Loader";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Context>
        <Outlet />
      </Context>
    </Suspense>
  );
}

export default App;
