import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { StorageKeys } from "../constants";
import { getWithExpiry } from "./storage";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer",
  },
});

axiosInstance.interceptors.request.use(
  async (value: AxiosRequestConfig) => {
    value.url = `${value?.baseURL}${value?.url}`;

    const token = getWithExpiry(StorageKeys.TOKEN_KEY);

    if (token) {
      value.headers = {
        ...value.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return value;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (err) => {
    if (err?.response?.status === 401) {
      // Auth.onSignOut()
    }

    console.log(
      `[Axios] [STATUS]: ${err.response?.status} [METHOD]: ${err.config.method} [URL]: ${err.config.url}`
    );

    return Promise.reject(err?.response?.data);
  }
);

export default axiosInstance;
