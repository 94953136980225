import { CircularProgress, Grid } from "@mui/material";

const Loader = () => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ width: "100%", height: "100vh" }}
    >
      <CircularProgress size={100} color={"primary"} />
    </Grid>
  );
};

export default Loader;
