import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { appRoutes } from "./routes";
import "./assets/fonts/SuisseIntl-Medium.ttf";
import "./assets/fonts/SuisseIntl-Regular.ttf";
import { RouterProvider } from "react-router-dom";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PC6KX9HQ",
  // gtmId: "GTM-WPVDGBTX", // Sandra's personal GTM ID for tests
  events: {
    start: new Date().getTime(),
  },
};

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_DEV_ENV !== "true"
) {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={appRoutes()} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
