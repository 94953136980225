import { SnackbarOrigin } from "@mui/material";

export const snackbarDesktop: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};
export const snackbarMobile: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

export enum StepEndpoints {
  STEP1 = `/signup/step1`,
  STEP2 = "/signup/step2",
  STEP3 = "/signup/step3",
  STEP3_EXISTING = "/signup/step3-existing",
  OFFER = "/signup/offer",
  CARDS = "/signup/stored_cards",
  SITES = "/sites",
  CHECKOUT_STATUS = "/signup",
  LOGIN = "/signup/login",
  PROFILE = "/account/profile",
}

export enum StorageKeys {
  USER_DATA_KEY = "userData",
  TOKEN_KEY = "token",
  USER_EMAIL_IDENTIFIER = "userEmailIdentifier",
  SLUG_KEY = "slug",
  OFFER_KEY = "offer",
  CURRENT_STEP = "current_step",
}

export const LOCATION_DEFAULT = "default";
export const DEFAULT_SECRET_KEY = "default key";
export const REFERRAL_ID = "id";
export const LIVESTREAM_LOCATION_ID = "48717";

export enum MixpanelEvents {
  OFFER_USED = "signup_offer_used",
  EMAIL_PAGE_VIEWED = "signup_email_address_page_viewed",
  REFERRAL_EMAIL_SUBMIT_TAPPED = "signup_referral_email_address_submit_tapped",
  STEP1_EMAIL_SUBMIT_TAPPED = "signup_step1_email_address_submit_tapped",
  EMAIL_SUBMIT_RESPONSE_RECEIVED = "signup_email_submit_response_received",
  ACCOUNT_EXIST_PAGE_VIEWED = "signup_account_exists_page-viewed",
  ACCOUNT_EXIST_BUTTON_TAPPED = "signup_account_exists_button-tapped",
  ACCOUNT_EXIST_LOGIN_PAGE_VIEWED = "signup_account_exists_login_page-viewed",
  ACCOUNT_EXIST_LOGIN_BUTTON_TAPPED = "signup_account_exists_login_button-tapped",
  CUSTOMER_DETAILS_PAGE_VIEWED = "signup_customer_details_page_viewed",
  CUSTOMER_DETAILS_BUTTON_TAPPED = "signup_customer_details_page_button_tapped",
  PAYMENT_METHOD_PAGE_VIEWED = "signup_payment_method_page_viewed",
  STUDIO_SELECTION_PAGE_VIEWED = "signup_studio_selection_page_viewed",
  STUDIO_SELECTION_BUTTON_TAPPED = "signup_studio_selection_button_tapped",
  STUDIO_SELECTION_FAILED = "signup_studio_selection_failed",
  PAYMENT_METHOD_PAGE_LINK_TAPPED = "signup_payment_method_page_link_tapped",
  PAYMENT_METHOD_PROCESSING_PAGE_VIEWED = "signup_payment_method_processing_page_viewed",
  PAYMENT_METHOD_SUCCESS_PAGE_VIEWED = "signup_payment_method_success_page_viewed",
  REFERRAL_LANDING_PAGE_VIEWED = "referrals_landing_page_viewed",
  EXISTING_PAYMENT_METHOD_PAGE_VIEWED = "signup_existing_payment_method_page_viewed",
  PAYMENT_METHOD_SUBMIT_TAPPED = "signup_payment_method_submit_tapped",
  NEW_PAYMENT_METHOD_SUBMIT_FAILED = "signup_new_payment_method_submit_failed",
  EXISTING_PAYMENT_METHOD_SUBMIT_FAILED = "signup_existing_payment_method_submit_failed",
}

export enum EnvironmentType {
  development = "dev",
  production = "prod",
  test = "test",
}

export enum STEPS {
  step2 = "step2",
  step3 = "step3",
  step4 = "step4",
  step5 = "step5",
}

export const GOOGLE_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.zingfit.BLOK_london";
export const APPLE_STORE_LINK =
  "https://apps.apple.com/us/app/blok-workouts-fitness/id1445073880";
export const BLOK_URL = "https://www.bloklondon.com/";
