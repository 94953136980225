import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import "./App.css";

const ImageComponent = lazy(() => import("./components/ImageComponent"));

function Steps() {
  return (
    <div className="App">
      <Grid container sx={{ height: "100%" }}>
        <ImageComponent />
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            minHeight: { md: "100%", xs: "55%" },
            height: { md: "100%", xs: "auto" },
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
}

export default Steps;
