import axiosInstance from "./index";
import { StepEndpoints } from "../constants";
import {
  CheckoutStatusResponse,
  GetUserCardListResponse,
  OfferInfo,
  Profile,
  Step1Response,
  SubmitStep2,
  UserData,
} from "../types";

export const getOfferInfo = (slug: string = "default") =>
  axiosInstance.get<null, OfferInfo>(`${StepEndpoints.OFFER}/${slug}`);

export const getCheckoutStatus = (username: string) =>
  axiosInstance.get<null, CheckoutStatusResponse>(
    `${StepEndpoints.CHECKOUT_STATUS}/${username}`
  );
export const getLoginLink = () =>
  axiosInstance.get<null, { login_uri: string }>(`${StepEndpoints.LOGIN}`);

export const postStep1 = (data: {
  username: string;
  source: string;
  trainingAdvice: boolean;
  slug?: string;
  referredBy?: string;
}) =>
  axiosInstance.post<
    {
      username: string;
      source: string;
      slug: string;
      trainingAdvice: boolean;
      referredBy?: string;
    },
    Step1Response
  >(StepEndpoints.STEP1, data);

export const postStep2 = (resp: SubmitStep2) =>
  axiosInstance.post<SubmitStep2, UserData>(StepEndpoints.STEP2, resp);

export interface PostStep3Request {
  username: string;
  cardHolderName: string;
  number: string;
  ccv: string;
  expiration_month: string;
  expiration_year: string;
  zip: string;
}
export const postStep3 = (data: PostStep3Request) =>
  axiosInstance.post(StepEndpoints.STEP3, data);

export interface CheckoutExistingCard {
  card_id: string;
  username: string;
}

export const postStep3Existing = (data: CheckoutExistingCard) =>
  axiosInstance.post(StepEndpoints.STEP3_EXISTING, data);

export type Site = {
  id: string;
  siteNumber: number;
  name: string;
  city: string;
  zip: string;
  phone: string;
  address: string;
  address2: string;
  timeZone: string;
  displayForPreferredLocation: boolean;
  private: boolean;
};

export const getSites = (): Promise<Site[]> =>
  axiosInstance.get(StepEndpoints.SITES);

export const getProfile = () =>
  axiosInstance.get<null, Profile>(StepEndpoints.PROFILE);

export const getUserCardListRequest = async (username: string) =>
  axiosInstance.get<{ username: string }, GetUserCardListResponse>(
    StepEndpoints.CARDS,
    { params: { username } }
  );
